import React, { useEffect, useState } from 'react';

import './App.css';
import { DrafterPage } from './Pages/DrafterPage/DrafterPage';
import { ShowResult } from './Pages/ShowResult/ShowResult';
import { DraftSettings } from './Pages/DraftSettings/DraftSettings';
let pool:any ={}

function App() {
  let [gameStep, setGameStep] = useState("mainMenu");
  let [result, setResult] = useState("mainMenu");
  let [cardsPerBooster, setCardsPerBooster] = useState(4)
  let [numberOfBoosters, setNumberOfBoosters] = useState(4)
  let [maxPlayers, setMaxPlayers] = useState(2)
  return (
    <div className="App">
      <div className="general-container">
        {(gameStep === "mainMenu") ? 
          ( <DraftSettings callback={({cardsPerBooster,numberOfBoosters}:any) => {
            setCardsPerBooster(cardsPerBooster);
            setNumberOfBoosters(numberOfBoosters);
            setGameStep("draft");
          }
        }/>): null
      }
        {(gameStep === "draft") ? 
          ( <DrafterPage 
            callback={(tmpResult)=>{setResult(tmpResult);setGameStep("result")}}
            cardsPerBooster={cardsPerBooster}
            numberOfBoosters={numberOfBoosters}
            maxPlayers={maxPlayers}
            />)
            : null
          }
        {(gameStep === "result") ? 
          ( <ShowResult data={result}/>): null
        }
        </div>
    </div>
  );
}

export default App;
