

export class Pool {
    constructor(){
        
        const data = require('../DB/data.json')
       
        this.rawPool = [...data]
        this.actualPool = [...data]
    }
    pickRandom(){
        let tmpPool = []
        if(this.actualPool.filter((card)=>card.energyType === "Thunder").length> 0)
            tmpPool.push(this.actualPool.filter((card)=>card.energyType === "Thunder"))
        if(this.actualPool.filter((card)=>card.energyType === "Mask").length> 0)
            tmpPool.push(this.actualPool.filter((card)=>card.energyType === "Mask"))
        if(this.actualPool.filter((card)=>card.energyType === "Shield").length> 0)
            tmpPool.push(this.actualPool.filter((card)=>card.energyType === "Shield"))
        if(this.actualPool.filter((card)=>card.energyType === "Fist").length> 0)
            tmpPool.push(this.actualPool.filter((card)=>card.energyType === "Fist"))
        tmpPool = tmpPool[Math.floor(Math.random()*tmpPool.length)]
        if (tmpPool.length === 0) return this.pickRandom()
        let characterToSelect = Object.keys(Object.groupBy(tmpPool,({character})=>character))
        characterToSelect = characterToSelect[Math.floor(Math.random()*characterToSelect.length)]
        tmpPool = tmpPool.filter((card)=>card.character === characterToSelect)
        const picked = tmpPool[Math.floor(Math.random()*tmpPool.length)]
        this.actualPool = this.actualPool
                            .filter((card)=>
                                card.character !== picked.character)
        return picked
    }
    reset(){
        this.actualPool = this.rawPool
    }
    showPool(){
        return this.actualPool
    }
    draft(number){
        let draft = []
        for(let i = 0; i<number; i++){
            draft.push(this.pickRandom())
        }
        return draft
    }
    addCharacter(characterName){
        const cardsToAdd =this.rawPool.filter((card)=>card.character === characterName)
        cardsToAdd.map((card)=>this.actualPool.push(card))
    }
}
