import { useEffect, useState } from "react"
//import css
import './ShowResult.css'
export function ShowResult({data}: any) {
    useEffect(() => {
        console.log(data)
    }, [data])
    return (
        <div className="ShowResult">
            <div className="result-header">
                <h1>Show result</h1>
            </div>
            {data.map((player:any, index:number) => {
                return (
                    <div key={index}>
                        <h2>Player {index + 1}</h2>
                        <div className="SR-image-grid">
                            {player.map((card:any, index:number) => {
                                return (
                                    <div key={card.character} className="SR-cardContainer" >
                                        <img src={`cards/${card.img}`} alt={`${card.character}`} />
                                        <div className="characterInfo">
                                        <div className="characterLabel">{card.character}</div>
                                        <div className="characterSubTitle">{card.subTitle}</div>
                                            </div>
                  
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )

}