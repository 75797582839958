import { Button, Card, Select } from "antd";
import { useState } from "react";
import './DraftSettings.css';
export function DraftSettings({callback}:any) {
    let [cardsPerBooster, setCardsPerBooster] = useState(4)
    let [numberOfBoosters, setNumberOfBoosters] = useState(6)

    return (
        <div className="DraftSettings">

        <Card  title="Draft Settings" className="cardSettings" bordered={true}
            
        >
            <div className="LabelInput">Cards per Booster</div>
            <Select 
                    options={[4,5,6,8].map((item:number)=>{return {value:item}})}
                    value={cardsPerBooster}
                    onChange={(value)=>setCardsPerBooster(value)}
                    style={{ width: "50%"}}/>

            <div className="LabelInput">Number of Boosters</div>
            <Select
                    options={[4,5,6,8,10,12].map((item:number)=>{return {value:item}})}
                    value={numberOfBoosters}
                    onChange={(value)=>setNumberOfBoosters(value)}
                    style={{ width: "50%"}}/>
            <p></p>
                <Button type="primary"
                        onClick={()=>callback({cardsPerBooster:cardsPerBooster,numberOfBoosters:numberOfBoosters})} >
                        Start
                </Button>
            </Card>
        </div>
    )
}