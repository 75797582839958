import { useEffect, useState } from "react";
import './DrafterPage.css';
import { Pool } from '../../scripts/drafter';
import { Button, Modal } from "antd";
let pool:any ={}
let round:number;
let turn:number;

type params = {
  callback:(result:any)=>void,
  cardsPerBooster?:number,
  maxPlayers?:number,
  numberOfBoosters?:number,
  resetAfterTurns?:number
}
export function DrafterPage({cardsPerBooster=4, maxPlayers=2,numberOfBoosters=6,resetAfterTurns=2,callback}: params) {
    const [allCardsToPick, setAllCardsToPick] = useState([]);
    const [playerTurn, setPlayerTurn] = useState(1);
    const [showPlayerModal, setShowPlayerModal] = useState(-1);

    const [result, setResult] = useState<any[]>([[],[]]);

    const showPickedCards = (player:any) => {
      setShowPlayerModal(player)
    }
    useEffect(() => {
      pool = new Pool();
      round = 1;
      turn = 1;
      setAllCardsToPick(pool.draft(cardsPerBooster));
    },[])
  
    const resetSelected = (confirmed = false) => {
      allCardsToPick.map((card:any, index:number) => {
        const container = document.getElementById(`cardContainer0${index}`)
        if(container)container.classList.remove("selected");
        if(confirmed){
          if(container)container.classList.remove("confirmed");
          const confirmedClass = document.getElementById(`confirmed_${index}`)
          if(confirmedClass)confirmedClass.style.display = "none";
        }
        const selected = document.getElementById(`mensaje_${index}`)
        if(selected)selected.style.display = "none";
      })
  
    }
    const selectCard = (cardIndex:number) => {
      const mensaje = document.getElementById(`mensaje_${cardIndex}`);
      const container = document.getElementById(`cardContainer0${cardIndex}`)

      if(container && container.classList.contains("confirmed")) return;
      if (mensaje && container) {
        if (container.classList.contains("selected")){

          // CONFIRMED
          //add card to player
            let tmp = result as any;
            tmp[playerTurn-1].push(allCardsToPick[cardIndex]);
            setResult(tmp);

          // change confirmed
          resetSelected();
          const confirmed = document.getElementById(`confirmed_${cardIndex}`);
          if (confirmed) confirmed.style.display = "block";
          mensaje.style.display = "none";
          
          container.classList.add("confirmed"); 
          turn++

          if (resetAfterTurns < turn){
            const notSelected = document.querySelectorAll(".pickableCard:not(.confirmed)")
            for (let i = 0; i < notSelected.length; i++){
              pool.addCharacter(notSelected[i].getAttribute("about"))
            }
            resetSelected(true);
            setAllCardsToPick(pool.draft(cardsPerBooster));
            turn = 1;
            round++;
            if (numberOfBoosters < round){
              callback(result);
            }
            
          }else{
            if(playerTurn === maxPlayers){
              setPlayerTurn(1)
            } else{
              setPlayerTurn(playerTurn + 1);
            }
          }
        
        } else{
          // Change selected
          resetSelected();
          container.classList.add("selected");
          mensaje.style.display = "block";
        }
  
      }
      
    }
    // const test = () => {
    //   resetSelected();
    //   setAllCardsToPick(pool.draft(cardsPerBooster));
    // }
return (
    <div className="DrafterPage">
      <Modal title= {`Player ${showPlayerModal+1} info`} open={[0,1].includes(showPlayerModal)} onCancel={()=>setShowPlayerModal(-1)}>
        <div className="image-grid ">

          {
          showPlayerModal>-1?
          result[showPlayerModal].map((card:any, cardIndex:number) =>
              <div className="cardContainer"
                about={`${card.character}`} 
                key={`cardContainer-show-${cardIndex}`}
                >
                <img src={`cards/${card.img}`} alt={`${card.character}`} />
                <div className="characterInfo">
                  <div className="characterLabel">{card.character}</div>
                  <div className="characterSubTitle">{card.subTitle}</div>
                </div>
              </div>
          ):null
        }
          </div>
      </Modal>
      <h1 >Choose a card:</h1>
      <h2>Player {playerTurn}</h2>
      <div className="image-grid ">
        {allCardsToPick.map((card:any, cardIndex:number) =>{
          return ( 
            <div key={`cardContainer0${cardIndex}`} 
                  id={`cardContainer0${cardIndex}`} className="cardContainer pickableCard"
                  about={`${card.character}`} 
                  >
              <img 
                  src={`cards/${card.img}`} 
                  alt={`${card.character}`} 
                  onClick={()=>selectCard(cardIndex)}/>
              <br/>
              <div className="characterInfo">
                <div className="characterLabel">{card.character}</div>
                <div className="characterSubTitle">{card.subTitle}</div>
              </div>

              <div className="message" id={`mensaje_${cardIndex}`}><b>Cards Selected</b></div>
              <div className="confirmed_msg" id={`confirmed_${cardIndex}`}><b>CONFIRMED</b></div>

            </div>
          )
        })}

      </div>
      <Button type="primary" onClick={()=>showPickedCards(0)}>Cards selected by Player 1</Button>
      <Button type="primary" onClick={()=>showPickedCards(1)}>Cards selected by Player 2</Button>

  </div>
)
}